<template>
  <b-card class="mx-auto mt-2">
    <div class="mx-auto mt-2">
      <vue-good-table
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          perPageDropdown: [10, 20, 50, 100],
          dropdownAllowAll: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading.sync="isLoading"
        :total-rows="totalRecords"
        :rows="resources"
        :columns="columnDefs"
        style-class="vgt-table condensed bordered w-100"
        compact-mode
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="onSelectionChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'label'">
            <b-badge
              v-for="label in props.row.label"
              :key="label.key"
              pill
              class="h-6"
              variant="warning"
            >
              {{ label }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'banner_image'">
            <CellRendererImage
              :params="{ banner_image: props.row.banner_image }"
            />
          </span>

          <span v-else-if="props.column.field === 'rating'">
            <div class="item-rating mt-1">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{ 'ml-25': star - 1 }"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= props.row.rating },
                      star <= props.row.rating ? 'text-primary' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
          </span>

          <span v-else-if="props.column.field === 'actions'">
            <div class="d-flex justify-content-between">
              <b-button
                v-b-tooltip.hover.top="`Click here to view more information.`"
                class="btn-icon grid-btn"
                variant="outline-primary"
                @click="viewMore(props.row.tag)"
              >
                <feather-icon
                  icon="EyeIcon"
                  svg-classes="hover:text-primary stroke-current"
                />
              </b-button>

              <b-button
                v-if="props.row.tag === 'riasec'"
                v-b-tooltip.hover.top="`Click here to send riasec questions.`"
                class="btn-icon grid-btn"
                variant="outline-primary"
                @click="performAction(props.row.tag)"
              >
                <feather-icon
                  icon="SendIcon"
                  svg-classes="hover:text-primary stroke-current"
                />
              </b-button>
            </div>
          </span>
        </template>
      </vue-good-table>
    </div>
    <b-row>
      <b-modal
        v-model="activateRiasec"
        centered
        hide-footer
        class=""
        title="Send Riasec Assessment"
      >
        <b-overlay
          :show="isUserLoading"
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div>
            <b-row class="mx-1">
              <h6>
                Select the users who you want to send the assessment to below:
              </h6>
            </b-row>
            <b-row class="my-1 mx-1">
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search user by email address or fullname"
                  class="search-user"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-row>

            <div class="w-100 float-left ml-2">
              <b-form-checkbox
                v-model="selectAllUsers"
                name="all_users"
                class="mr-2"
                :value="true"
              >
                <span>Select all <b>{{ userCount }} users</b>
                </span>
              </b-form-checkbox>
              <hr class="mr-4">
            </div>

            <div class="w-100 float-left mx-2">
              <b-form-checkbox-group v-model="selectedUsers">
                <b-form-checkbox
                  v-for="(item, index) in allUsers"
                  :key="index"
                  name=""
                  class="mr-2 my-1"
                  style=""
                  :value="item.id"
                >
                  <span>{{ item["fullname"] }} - </span>
                  <span>({{ item["email_address"] }})</span>
                </b-form-checkbox>
              </b-form-checkbox-group>
              <hr class="mr-4">
            </div>

            <div class="d-flex w-100">
              <SpinnerBtn v-if="isBtnLoading" />
              <b-button
                v-else
                class="mx-auto my-2"
                variant="primary"
                @click="sendRiasecLinks"
              >Send Assessment Links</b-button>
            </div>
          </div>
        </b-overlay>
      </b-modal>
    </b-row>
    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </b-card>
</template>

<script>
import { debounce } from 'lodash'
import Ripple from 'vue-ripple-directive'
import { completionMixins } from '@/components/mixins/CompletionMixins'
import { VueGoodTable } from 'vue-good-table'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'
import CellRendererImage from '../EventManager/cell-renderer/CellRendererImage.vue'
import CellRendererCategory from '../EventManager/cell-renderer/CellRendererCategory.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    CellRendererImage,
    CellRendererCategory,
    SpinnerBtn,
  },

  mixins: [completionMixins],
  data() {
    return {
      isLoading: false,
      isUserLoading: false,
      isBtnLoading: false,
      searchQuery: '',
      userCount: 0,
      totalRecords: 0,
      resources: [],
      selectedUsers: [],
      selectAllUsers: false,

      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          // pinned: true,
        },
        {
          label: 'Banner',
          field: 'banner_image',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Label',
          field: 'label',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },

        {
          label: 'Views',
          field: 'views',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },

        {
          label: 'Rating',
          field: 'rating',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },

        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Created On',
          field: 'created_on',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        // {
        //   label: 'Deactivated At',
        //   field: 'deactivated_at',
        //   filter: true,
        //   width: 150,
        // },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-right',
          tdClass: 'text-right',
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      activateRiasec: false,
      exportingCSVIndex: null,
      exportingCSV: false,
      selectedRows: null,
      mqShallShowLeftSidebar: false,
      allUsers: [],
    }
  },
  watch: {
    searchQuery(value) {
      this.searchForBotUser(value, this)
    },
  },
  async created() {
    // this.resources = [
    //   {
    //     name: 'User Manual',
    //     by: 'HeadHunters HQ',
    //     email: 'career@headhuntershq.com',
    //     description: '',
    //     image: await this.$generateDownloadUrl('media/black_hq_logo.png'),
    //     id: '1',
    //   },
    //   // {
    //   //   name: 'Interview Training Video', by: 'Alvigor', description: '', image: `${this.$http.defaults.baseURL}/api/media/employer_training.jpg`, id: '2',
    //   // },
    //   {
    //     name: 'Interview Training Video',
    //     by: 'Alvigor',
    //     email: 'jobsecurity@e2i.com.sg',
    //     description: '',
    //     image: await this.$generateDownloadUrl('media/applicant_training.jpg'),
    //     id: '3',
    //   },
    // ]
  },
  mounted() {
    const incompleteProfile = this.checkProfileCompletion()

    // this.$generatePresignedUrl('event-images/uuid-filename.jpg')
    //   .then(response => {
    // })

    if (incompleteProfile) {
      this.popupProfileQuestions = true
    }

    this.initComponent()
  },
  methods: {
    async sendRiasecLinks() {
      this.isBtnLoading = true
      try {
        const response = await this.$http.post('/api/riasec', {
          riasec_info: {
            user_ids: this.selectedUsers,
            all_users: this.selectAllUsers,
          },
        })
        if (response.data.success) {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                text: 'Riasec test(s) sent successfully.',
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
          this.isBtnLoading = false
        }
      } catch (error) {
        console.error('F', error)
      }
    },
    viewMore(tag) {
      if (tag === 'riasec') {
        this.$router.push({ name: 'riasec-results' })
      }
    },
    searchForBotUser: debounce((search, vm) => {
      vm.isUserLoading = true
      vm.getBotUsers()
    }, 1000),
    performAction(tag) {
      if (tag === 'riasec') {
        this.isUserLoading = true
        this.getBotUsers()
        this.activateRiasec = true
      }
    },
    getRecords() {
      const query = `?params=${JSON.stringify(this.serverParams)}`
      this.$http
        .get(`/api/resources${query}`)
        .then(response => {
          const { success } = response.data
          if (success) {
            this.resources = response.data.all_resources
            this.totalRecords = response.data.total_records
            this.isLoading = false
          }
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    getBotUsers() {
      let url = '/api/mongo-users'
      if (this.searchQuery.length > 0) {
        url += `?query=${this.searchQuery}`
      }
      this.$http
        .get(url)
        .then(response => {
          const { success, users, count } = response.data
          if (success) {
            this.allUsers = users
            this.isUserLoading = false
            this.userCount = count
          }
        })
        .catch(error => {
          this.isUserLoading = false
        })
    },
    async initComponent() {
      const { account_type } = this.$store.state.auth.ActiveUser
      if (account_type === this.$AccountTypes.APPLICANT) {
        this.resources = []
          
      } else if (
        account_type === this.$AccountTypes.AGENCY
        || account_type === this.$AccountTypes.EMPLOYER
      ) {
        this.getRecords()
        // this.resources = [
        //   {
        //     url: '/resources/user-guide',
        //     name: 'User Manual',
        //     by: 'HeadHunters HQ',
        //     description: '',
        //     image: await this.$generatePresignedUrl('media/black_hq_logo.png'),
        //     id: '1',
        //   },
        //   {
        //     url: '/resources/training',
        //     name: 'Interview Training Video',
        //     by: 'Alvigor',
        //     description: '',
        //     image: await this.$generatePresignedUrl('media/employer_training.jpg'),
        //     id: '2',
        //   },
        // ]
      }
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      // this.getData()
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },

    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = {}
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },

    onColumnFilter(params) {
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },
  },
}
</script>
